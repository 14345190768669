import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import CaseStudySection from "../elements/parts/CaseStudySection";
import FaqSection from "../elements/parts/FaqSection";
import NewsletterSection from "../elements/parts/NewsletterSection";

export default function Projects() {
  return (
    <>
      <SeconderyBanner title="Recent Projects" />
      <CaseStudySection />
      <FaqSection />
      <NewsletterSection />
    </>
  );
}
