import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
export default function TestimonialSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <button className="owl-prev slick-prev">Previous</button>,
    nextArrow: <button className="owl-next slick-next">Next</button>,
  };

  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Testimonial
            </div>
            <h1 className="mb-4">What Say Our Clients!</h1>
            <p className="mb-4">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
            <Link className="btn btn-primary rounded-pill px-4" to="#">
              Read More
            </Link>
          </div>
          <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
            <Slider {...settings} className="owl-carousel testimonial-carousel">
              <div className="testimonial-item ps-5">
                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                <p className="fs-4">
                  Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
                  et sit, sed stet no labore lorem sit. Sanctus clita duo justo
                  et tempor eirmod magna dolore erat amet
                </p>
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid flex-shrink-0 rounded-circle"
                    src={require("assets/img/testimonial-1.jpg").default}
                    alt="Client 1"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div className="ps-3">
                    <h5 className="mb-1">Client Name</h5>
                    <span>Profession</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-item ps-5">
                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                <p className="fs-4">
                  Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
                  et sit, sed stet no labore lorem sit. Sanctus clita duo justo
                  et tempor eirmod magna dolore erat amet
                </p>
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid flex-shrink-0 rounded-circle"
                    src={require("assets/img/testimonial-2.jpg").default}
                    alt="Client 2"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div className="ps-3">
                    <h5 className="mb-1">Client Name</h5>
                    <span>Profession</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-item ps-5">
                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                <p className="fs-4">
                  Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
                  et sit, sed stet no labore lorem sit. Sanctus clita duo justo
                  et tempor eirmod magna dolore erat amet
                </p>
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid flex-shrink-0 rounded-circle"
                    src={require("assets/img/testimonial-3.jpg").default}
                    alt="Client 3"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div className="ps-3">
                    <h5 className="mb-1">Client Name</h5>
                    <span>Profession</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
