import React from "react";

export default function FaqSection() {
  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div
          className="mx-auto text-center wow fadeIn"
          data-wow-delay="0.1s"
          style={{ maxWidth: "500px" }}
        >
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
            Popular FAQs
          </div>
          <h1 className="mb-4">Frequently Asked Questions</h1>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ1">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    What is included in your web design service?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Our web design service includes custom design, responsive layouts, user experience (UX) design, and integration with your existing systems or CMS.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.2s">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What does your mobile app development process involve?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Our mobile app development process includes ideation, design, development, testing, deployment, and post-launch support and maintenance.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.3s">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Can you integrate ERP systems with our existing software?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Yes, we can integrate ERP systems with your existing software to streamline processes and improve data flow across your organization.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.4s">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What eCommerce platforms do you work with?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    We work with various eCommerce platforms including Shopify, Magento, WooCommerce, and custom-built solutions tailored to your business needs.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How do you ensure the security of our website?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    We implement various security measures including SSL certificates, regular security audits, and secure coding practices to protect your website from threats.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.6s">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What does your SEO service include?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Our SEO service includes keyword research, on-page optimization, link building, content creation, and regular performance monitoring to improve your search engine rankings.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.7s">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Do you offer custom domain registration services?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Yes, we offer custom domain registration services to help you choose and register a domain name that best represents your brand.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.8s">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    What type of hosting services do you provide?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    We provide various hosting services including shared hosting, VPS, dedicated servers, and cloud hosting solutions tailored to your needs.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="0.9s">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    How do you handle website maintenance?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    We offer ongoing website maintenance services including updates, backups, security patches, and performance optimization to keep your site running smoothly.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.0s">
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    Can you help with social media integration on our website?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    Yes, we can integrate social media platforms with your website, allowing for seamless sharing, social login options, and displaying social feeds.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.1s">
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    What is the importance of having a backup strategy?
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    A backup strategy is crucial to protect your data from loss due to accidental deletion, hacking, or hardware failure, ensuring quick recovery.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.2s">
                <h2 className="accordion-header" id="headingTwelve">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    How do you approach user experience (UX) design?
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div className="accordion-body">
                    We approach UX design by understanding user needs, creating intuitive interfaces, conducting usability testing, and iterating based on feedback.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ2">
              <div className="accordion-item wow fadeIn" data-wow-delay="1.3s">
                <h2 className="accordion-header" id="headingThirteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    What is your approach to SEO strategy?
                  </button>
                </h2>
                <div
                  id="collapseThirteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    Our SEO strategy includes comprehensive keyword research, on-page optimization, high-quality content creation, and building authoritative backlinks.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.4s">
                <h2 className="accordion-header" id="headingFourteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    Can you develop custom plugins or extensions?
                  </button>
                </h2>
                <div
                  id="collapseFourteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFourteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    Yes, we can develop custom plugins or extensions to enhance the functionality of your website or eCommerce platform based on your specific needs.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.5s">
                <h2 className="accordion-header" id="headingFifteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFifteen"
                    aria-expanded="false"
                    aria-controls="collapseFifteen"
                  >
                    What kind of support do you provide post-launch?
                  </button>
                </h2>
                <div
                  id="collapseFifteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFifteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    We provide ongoing support post-launch, including troubleshooting, updates, security patches, and performance enhancements to ensure your website's continued success.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.6s">
                <h2 className="accordion-header" id="headingSixteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                    How can I track the success of my SEO efforts?
                  </button>
                </h2>
                <div
                  id="collapseSixteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSixteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    You can track SEO success using analytics tools to monitor rankings, traffic, conversion rates, and user behavior, adjusting strategies based on performance data.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.7s">
                <h2 className="accordion-header" id="headingSeventeen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                    What are the benefits of using cloud hosting?
                  </button>
                </h2>
                <div
                  id="collapseSeventeen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeventeen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    Cloud hosting offers scalability, reliability, and cost-efficiency, allowing for easy management of resources and handling of high traffic volumes with minimal downtime.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.8s">
                <h2 className="accordion-header" id="headingEighteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEighteen"
                    aria-expanded="false"
                    aria-controls="collapseEighteen"
                  >
                    How do you ensure a seamless UX for mobile users?
                  </button>
                </h2>
                <div
                  id="collapseEighteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEighteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    We ensure a seamless UX for mobile users by designing mobile-friendly interfaces, optimizing load times, and ensuring intuitive navigation and functionality on smaller screens.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="1.9s">
                <h2 className="accordion-header" id="headingNineteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNineteen"
                    aria-expanded="false"
                    aria-controls="collapseNineteen"
                  >
                    What is your approach to integrating AI in web applications?
                  </button>
                </h2>
                <div
                  id="collapseNineteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNineteen"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    We integrate AI into web applications by implementing machine learning algorithms, chatbots, recommendation systems, and data analytics to enhance functionality and user experience.
                  </div>
                </div>
              </div>
              <div className="accordion-item wow fadeIn" data-wow-delay="2.0s">
                <h2 className="accordion-header" id="headingTwenty">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwenty"
                    aria-expanded="false"
                    aria-controls="collapseTwenty"
                  >
                    How can I improve my website’s conversion rate?
                  </button>
                </h2>
                <div
                  id="collapseTwenty"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwenty"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div className="accordion-body">
                    To improve your website’s conversion rate, focus on optimizing your calls-to-action, improving site speed, enhancing UX/UI design, and utilizing A/B testing to refine strategies.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
