import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import AbouSection from "../elements/parts/AbouSection";
import FeatureSection from "../elements/parts/FeatureSection";
import TeamSection from "../elements/parts/TeamSection";

const About = () => {
  return (
    <div>
      <SeconderyBanner title="About Us" />
     
      <AbouSection />
     <FeatureSection/>
      <TeamSection/>
      
    </div>
  );
};

export default About;
