import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import TeamSection from "../elements/parts/TeamSection";
import NewsletterSection from "../elements/parts/NewsletterSection";

export default function Team() {
  return (
    <>
      <SeconderyBanner title="Our Team" />
      <TeamSection />
      <NewsletterSection />
    </>
  );
}
