import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import ServiceSection from "../elements/parts/ServiceSection";
import FeatureSection from "../elements/parts/FeatureSection";

export default function Feature() {
  return (
    <>
      <SeconderyBanner title="Features" />
      <ServiceSection />
      <FeatureSection />
    </>
  );
}
