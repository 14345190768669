import Translator, { __ } from "services/Translator";

//Auth module
import PrivateRoute from "routes/PrivateRoute";
import OpenRoute from "routes/OpenRoute";

// import external modules
import React, { Component, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Home from "pages/Home";
import About from "pages/About";
import Services from "pages/Services";
import Contact from "../pages/Contact";
import Faq from "../pages/Faq";
import Feature from "../pages/Feature";
import Project from '../pages/Projects';
import Team from "../pages/Team";
import Testimonial from "../pages/Testimonial";
import NotFound from "../pages/NotFound";


function OpenRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <OpenRoute exact path="/" component={Home}></OpenRoute>
        <OpenRoute exact path="/about" component={About}></OpenRoute>
        <OpenRoute exact path="/service" component={Services}></OpenRoute>
        <OpenRoute exact path="/contact" component={Contact}></OpenRoute>
        <OpenRoute exact path="/faq" component={Faq}></OpenRoute>
        <OpenRoute exact path="/feature" component={Feature}></OpenRoute>
        <OpenRoute exact path="/project" component={Project}></OpenRoute>
        <OpenRoute exact path="/team" component={Team}></OpenRoute>
        <OpenRoute exact path="/testimonial" component={Testimonial}></OpenRoute>
        <OpenRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/" component={Home}></PrivateRoute>

          <PrivateRoute path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
