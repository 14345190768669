import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const pagePath = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`container-fluid sticky-top ${
        isSticky ? "theme_bg_color shadow-sm" : ""
      }`}
      style={{ top: isSticky ? "0px" : "-150px" }}
    >
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <Link to="/" className="navbar-brand">
            <img
              className="img-fluid"
              style={{ maxWidth: "200px" }}
              src={require("assets/img/logo-full.jpg").default}
              alt="Hero"
            />
            {/* <h1 className="text-white">
              AI<span className="text-dark">.</span>Tech
            </h1> */}
          </Link>
          <button
            type="button"
            className="navbar-toggler ms-auto me-0"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link
                to="/"
                className={
                  pagePath === "/"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Home
              </Link>
              <Link
                to="/about"
                className={
                  pagePath === "/about"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                About
              </Link>
              <Link
                to="/service"
                className={
                  pagePath === "/service"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Services
              </Link>
              <Link
                to="/project"
                className={
                  pagePath === "/project"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Projects
              </Link>
              <div className="nav-item dropdown d-none">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </Link>
                <div className="dropdown-menu bg-light mt-2">
                  <Link
                    to="/feature"
                    className={
                      pagePath === "/feature"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    Features
                  </Link>
                  <Link
                    to="/team"
                    className={
                      pagePath === "/team"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    Our Team
                  </Link>
                  <Link
                    to="/faq"
                    className={
                      pagePath === "/faq"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    FAQs
                  </Link>
                  <Link
                    to="/testimonial"
                    className={
                      pagePath === "/testimonial"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    Testimonial
                  </Link>
                  <Link
                    to="/404"
                    className={
                      pagePath === "/404"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    404 Page
                  </Link>
                </div>
              </div>
              <Link
                to="/contact"
                className={
                  pagePath === "/contact"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Contact
              </Link>
            </div>
            <button
              type="button"
              className="btn text-white p-0 d-none"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
