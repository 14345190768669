import React from "react";
import { Link } from "react-router-dom";

export default function AbouSection() {
  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img">
              <img
                className="img-fluid"
                src={require("assets/img/about-img.jpg").default}
                alt="About Us"
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Who We Are
            </div>
            <h1 className="mb-4">
              Crafting Digital Excellence with Passion and Precision
            </h1>
            <p className="mb-4">
              Infinacy Infotech is a full-service digital agency dedicated to
              empowering businesses through innovative technology solutions.
              Since our inception, we have been at the forefront of digital
              transformation, helping businesses of all sizes harness the power
              of technology to achieve their goals. Our team of experts is
              passionate about delivering exceptional service and creating
              solutions that not only meet but exceed our clients' expectations.
            </p>
            <p className="mb-4">
              Our mission is to provide our clients with cutting-edge technology
              solutions that drive business growth and success. We are committed
              to continuous innovation, ensuring that we stay ahead of industry
              trends and deliver solutions that are both current and
              forward-thinking.
            </p>
            <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2"></i>Quality
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2"></i>
                  Innovation
                </h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2"></i>Collaboration
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2"></i> Customer
                  Satisfaction
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center mt-4">
              <Link className="btn btn-primary rounded-pill px-4 me-3" to="/about">
                Read More
              </Link>
              <Link className="btn btn-outline-primary btn-square me-3" to="#">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link className="btn btn-outline-primary btn-square me-3" to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link className="btn btn-outline-primary btn-square me-3" to="#">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link className="btn btn-outline-primary btn-square" to="#">
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
