import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import TestimonialSection from "../elements/parts/TestimonialSection";
import NewsletterSection from "../elements/parts/NewsletterSection";

export default function Testimonial() {
  return (
    <>
      <SeconderyBanner title="Testimonial" />
      <TestimonialSection />
      <NewsletterSection />
    </>
  );
}
