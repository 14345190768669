import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <Link to="/" className="d-inline-block mb-3">
              <img
                className="img-fluid"
                style={{ maxWidth: "200px" }}
                src={require("assets/img/logo-full.jpg").default}
                alt="Hero"
              />
            </Link>
            <p className="mb-0">
              Infinacy Infotech, established in 2013, delivers exceptional
              digital solutions via Upwork, Fiverr, Freelancer, and other
              marketplaces, as well as through direct client communication.
            </p>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-map-marker-alt me-3"></i>House #587, Road #9,
              Avenue 4, Mirpur DOHS, Dhaka-1216, Bangladesh
            </p>
            <p>
              <i className="fa fa-phone-alt me-3"></i>+8801715363296
            </p>
            <p>
              <i className="fa fa-envelope me-3"></i>nazmul@infinacy.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-instagram"></i>
              </a>
              <a
                target="_blank"
                className="btn btn-outline-light btn-social"
                href="https://www.linkedin.com/in/nbcipher"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <h5 className="text-white mb-4">Popular Link</h5>
            <Link className="btn btn-link" to="/about">
              About Us
            </Link>
            <Link className="btn btn-link" to="/contact">
              Contact Us
            </Link>
            <Link className="btn btn-link" to="/team">
              Our Team
            </Link>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
            <h5 className="text-white mb-4">Our Services</h5>
            <Link className="btn btn-link" to="/service">
              Web & Mobile Application
            </Link>
            <Link className="btn btn-link" to="/service">
              ERP & Ecommerce Solutions
            </Link>
            <Link className="btn btn-link" to="/service">
              Domain & Hosting
            </Link>
            <Link className="btn btn-link" to="/service">
              Security & SEO
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
