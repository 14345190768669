import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

export default function ScrollTo() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <Link
      to="#"
      className={
        show
          ? "btn btn-lg btn-primary btn-lg-square back-to-top pt-2"
          : "d-none"
      }
    >
      <i class="bi bi-arrow-up"></i>
    </Link>
  );
}
