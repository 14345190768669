import React from "react";
import SeconderyBanner from "../elements/parts/SeconderyBanner";
import ContactSection from "../elements/parts/ContactSection";
import NewsletterSection from "../elements/parts/NewsletterSection";

const Contact = () => {
  return (
    <>
      <SeconderyBanner title="Contact Us" />
      <ContactSection />
      <NewsletterSection />
    </>
  );
};

export default Contact;
