import React from "react";
import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <div className="container-fluid pt-5 theme_bg_color hero-header  mb-5">
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">
              WE DO IT
            </div>
            <h1 className="display-4 text-white mb-4 animated slideInRight">
              Empowering Your Digital Journey
            </h1>
            <p className="text-white mb-4 animated slideInRight">
              Web & Mobile Development, ERP Solution, E-commerce, Security,
              Hosting & More – We Do It All
            </p>
            <Link
              to="/service"
              className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight"
            >
              Read More
            </Link>
            <Link
              to="/contact"
              className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
            >
              Transform Your Business Today
            </Link>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img
              className="img-fluid"
              src={require("assets/img/home-bg.png").default}
              alt="Hero"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
