import React, { useState, useEffect } from "react";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Spinner from "elements/Spinner";
const FullLayout = ({ children, ...rest }) => {
  const [spinner, setSpinner] = useState(false);
  const passableParams = {
    setSpinner,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  return (
    <>
      {spinner && <Spinner />}
      <Header {...passableParams} />
      <main>{childrenWithProps}</main>
      <Footer {...passableParams} />
      <ScrollTo />
    </>
  );
};

export default FullLayout;
