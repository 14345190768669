import React from "react";
import { Link } from "react-router-dom";

export default function SeconderyBanner({ title }) {
  return (
    <div className="container-fluid pt-5 theme_bg_color hero-header">
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <h1 className="display-4 text-white mb-4 animated slideInRight">
              {title}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                <li className="breadcrumb-item">
                  <Link className="text-white" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link className="text-white" to="#">
                    Pages
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  {title}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img
              className="img-fluid"
              src={require("assets/img/cube.png").default}
              alt=""
              style={{ maxHeight: "300px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
