import React from "react";
import { Link } from "react-router-dom";

export default function ServiceSection() {
  return (
    <div className="container-fluid bg-light mt-5 py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Our Services
            </div>
            <h1 className="mb-4">Our Comprehensive Digital Services</h1>
            <p className="mb-4">
              At Infinacy Infotech, we offer a comprehensive suite of digital
              services designed to drive your business forward. From
              cutting-edge web design and development to powerful ERP solutions,
              secure e-commerce platforms, and robust security systems, our
              expertise covers every aspect of your digital needs. Whether
              you're looking to enhance your online presence, streamline
              operations, or boost your digital marketing efforts, we have the
              tools and talent to deliver results that exceed expectations. Let
              us help you turn your vision into reality.
            </p>
            <Link className="btn btn-primary rounded-pill px-4"  to="/service">
              Read More
            </Link>
          </div>
          <div className="col-lg-7">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i class="fa fa-laptop-code fa-2x"></i>
                      </div>
                      <h5 className="mb-3">Web & Mobile Application</h5>
                      <p>
                        Web and mobile apps connect businesses with users,
                        offering tailored experiences across devices, enhancing
                        engagement, and driving efficiency in the digital
                        landscape.
                      </p>
                      <Link className="btn px-3 mt-auto mx-auto" to="/service">
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i class="fa fa-chart-line fa-2x"></i>
                      </div>
                      <h5 className="mb-3">ERP & Ecommerce Solutions</h5>
                      <p>
                        ERP streamlines business operations by integrating
                        processes, while eCommerce solutions enable online
                        sales, providing businesses with tools to manage both
                        efficiently in the digital marketplace.
                      </p>
                      <Link className="btn px-3 mt-auto mx-auto" to="/service">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-md-4">
                <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i class="fa fa-server fa-2x"></i>
                      </div>
                      <h5 className="mb-3">Domain & Hosting</h5>
                      <p>
                        Domain and hosting services provide a unique web address
                        and reliable server space, ensuring your website is
                        accessible, fast, and secure for users worldwide.
                      </p>
                      <Link className="btn px-3 mt-auto mx-auto" to="/service">
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i className="fa fa-search fa-2x"></i>
                      </div>
                      <h5 className="mb-3">Security & SEO </h5>
                      <p>
                        Security protects your digital assets from threats,
                        while SEO enhances online visibility, driving traffic
                        and ensuring your website ranks higher in search engine
                        results.
                      </p>
                      <Link className="btn px-3 mt-auto mx-auto" to="/service">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
