import React, { useEffect } from "react";

import SeconderyBanner from "../elements/parts/SeconderyBanner";
import ServiceSection from "../elements/parts/ServiceSection";
import TestimonialSection from "../elements/parts/TestimonialSection";

export default function Services() {
  return (
    <div>
      <SeconderyBanner title="Our Services" />

      <ServiceSection />
      <TestimonialSection />

    </div>
  );
}
