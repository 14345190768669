import React from "react";
import Banner from "../elements/parts/Banner";
import AbouSection from "../elements/parts/AbouSection";
import ServiceSection from "../elements/parts/ServiceSection";
import FeatureSection from "../elements/parts/FeatureSection";
import CaseStudySection from "../elements/parts/CaseStudySection";
import FaqSection from "../elements/parts/FaqSection";
import TeamSection from "../elements/parts/TeamSection";
import NewsletterSection from "../elements/parts/NewsletterSection";

export default function Home() {
  return (
    <div>
      <Banner />
      <AbouSection />
      <ServiceSection />
      <FeatureSection />
      <CaseStudySection />
      <FaqSection />
      <TeamSection />
      <NewsletterSection />
    </div>
  );
}
