import React from "react";

export default function FeatureSection() {
  return (
    <div className="container-fluid theme_bg_color feature pt-5">
      <div className="container pt-5">
        <div className="row g-5">
          <div
            className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn"
            data-wow-delay="0.3s"
          >
            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
              Why Choose Us
            </div>
            <h1 className="text-white mb-4">Why Choose Infinacy Infotech?</h1>
            <p className="text-light mb-4">
              At Infinacy Infotech, we deliver top-notch digital solutions
              tailored to your business needs. Our commitment to quality,
              innovation, and customer satisfaction sets us apart in the
              industry.
            </p>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>

              <span>
                <b>Expert Team: </b>Skilled professionals with years of
                experience.
              </span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span>
                <b>Custom Solutions: </b>Tailored strategies to fit your
                business goals.
              </span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span>
                <b>Cutting-Edge Technology: </b>We utilize the latest tools and
                technologies.
              </span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span>
                <b>24/7 Support: </b>Reliable customer service whenever you need
                it.
              </span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span>
                <b>Proven Results: </b> A track record of successful projects
                and satisfied clients.
              </span>
            </div>
            <div className="row g-4 pt-3">
              <div className="col-sm-6">
                <div
                  className="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                  <i className="fa fa-users fa-3x text-white"></i>
                  <div className="ms-3">
                    <h2 className="text-white mb-0">100+</h2>
                    <p className="text-white mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                  <i className="fa fa-check fa-3x text-white"></i>
                  <div className="ms-3">
                    <h2 className="text-white mb-0">1000+</h2>
                    <p className="text-white mb-0">Project Complete</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 align-self-end text-center text-md-end wow fadeIn"
            data-wow-delay="0.5s"
          >
            <img
              className="img-fluid"
              src={require("assets/img/home-banner.png").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
